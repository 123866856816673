import React from "react"
import ReactMarkdown from 'react-markdown'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useI18next} from "gatsby-plugin-react-i18next"

import Layout from "../components/Layout";
import Title from "../components/Title"
import Seo from "../components/Seo"

const About = ({data}) => {
	const { language } = useI18next();
	const { allStrapiAbout: {nodes} } = data;
	const { title, info } = nodes.find(about => about.locale.split('-')[0] === language);
  return (
    <Layout>
			<Seo title="Sobre mí" />
			<main className="about-page">
				<section className="about">
					<div className="section-center about-center">
						{/* <img src={image.localFile.publicURL} alt={title} className="about-img-svg" /> */}
						<StaticImage
							src="../assets/images/me.png"
							alt="portfolio"
							layout="fixed"
							key="portfolio img"
							className="about-img"
							placeholder="blurred"
						/>
						<article className="about-text">
							<Title title={title} />
							<div style={{display: "flex", flexDirection: "row"}}>
								<div style={{"textAlign": 'justify'}}>
									<ReactMarkdown>{info}</ReactMarkdown>
								</div>
							</div>
						</article>
					</div>
				</section>
			</main>
    </Layout>
  )
}

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
		allStrapiAbout {
    nodes {
      id
      title
      info
      locale
      image {
        localFile {
          publicURL
        }
      }
    }
  }
	}
`

export default About
